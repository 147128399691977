//import { height } from "@mui/system";

import React from "react";
import {ParallaxBanner} from "react-scroll-parallax";

const Parallaximg = (props) => {
    let contentStyle={
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'end',
        height: '100%',
        color: '#fff',
        textShadow: `2px 2px 4px black, 0 0 3em black, 0 0 3em blue`,
        backgroundColor: `rgba(0,0,0,${ props.opacity == null && '0'})`,
        WebkitFilter: 'bluer(0px) saturate(2)',
       
    }
    return (
        <ParallaxBanner 
        layers={[{image: props.imgsrc , speed: -20}]}
        style={{height: props.height}}
        >
            <div style={contentStyle}>
               {props.children}
            </div>
        </ParallaxBanner>
    )
}

export default Parallaximg;